export enum AccountType {
  ADMIN = 'ADMIN',
  TECHNICIAN = 'TECHNICIAN',
  CUSTOMER = 'CUSTOMER',
}

export enum CustomerRole {
  INDIVIDUAL = 'Individual',
  CORPORATE = 'Corporate',
}

export interface LoadingEmitData {
  display: boolean;
  message: string;
}

export interface UploadUrlDto {
  fileId: string;
  preSignUrl: string;
  loadUrl: string;
  loadTempUrl?: string;
}

export interface FilterQueryBase {
  offset?: number;

  limit?: number;
}

export interface FilterQuery {
  offset?: number;

  limit?: number;

  search?: string;

  all?: boolean;
}

export enum ResidentialType {
  HDB = 'HDB',
  Condo = 'Condo',
  Landed = 'Landed',
}
